import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageLayout from "../components/PageLayout";
import { Link } from "gatsby";

const DataTreatment = () => (
  <PageLayout>
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} className="mt-3">
          <Link to="/" className="redirect">
            Regresar al Home
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <h3 className="mt-5 text-center text-uppercase">
            POL&Iacute;TICAS GENERALES Y DE TRATAMIENTO DE LA INFORMACI&Oacute;N
          </h3>
          <hr className="title-line" />
          <section className="mt-5">
            <h3 className="text-center mb-3">1</h3>
            <h4 className="text-center">Generalidades</h4>
            <p className="text-justify">
              La presente política se define de conformidad con la entrada en
              vigencia de la Ley Estatutaria 1581 de 2012 la cual tiene por
              objeto dictar las disposiciones generales para la protección de
              datos personales y desarrollar el derecho constitucional que
              tienen todas las personas a conocer, actualizar y rectificar las
              informaciones que se hayan recogido sobre ellas en bases de datos
              o archivos así como el derecho a la información; por tanto
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, teniendo en cuenta su
              condición de responsable del tratamiento de datos de carácter
              personal que le asiste, se permite formular el presente texto con
              el fin de dar efectivo cumplimiento a dicha normatividad y en
              especial para la atención de consultas y reclamos acerca del
              tratamiento de los datos de carácter personal que recoja y maneje
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, El derecho al{" "}
              <b>HÁBEAS DATA</b> es aquel que tiene toda persona de conocer,
              actualizar y rectificar la información que se haya recogido sobre
              ella en archivos y bancos de datos de naturaleza pública o privada
              y les garantiza a todos los ciudadanos poder de decisión y control
              sobre su información personal. Por tanto,
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, acoge tales disposiciones
              teniendo en cuenta que, para el desarrollo de su objeto social,
              continuamente está recopilando y efectuando diversos tratamientos
              a bases de datos tanto de clientes, proveedores y empleados.
            </p>
            <p className="text-justify">
              En virtud de lo anterior, dentro del deber legal y corporativo de
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, de proteger las políticas del
              manejo de la información y el derecho a la privacidad de las
              personas, así como la facultad de conocer, actualizar o solicitar
              la información que sobre contenga nuestras bases de datos.{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, diseño esta política de manejo
              de la información de carácter personal y bases de datos en la cual
              se describe y explica el tratamiento de la Información Personal a
              la que tiene acceso a través de nuestro sitio web, correo
              electrónico, llamadas telefónicas, atención personal, medios
              físicos o electrónicos, actuales o que en el futuro se desarrollen
              como otras comunicaciones enviadas a todos nuestros clientes,
              empleados y proveedores.
            </p>
            <p className="text-justify">
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, está comprometida con el
              respeto y garantía de los derechos de sus clientes, empleados y
              terceros en general. Por eso adopta el siguiente manual de
              políticas y procedimientos de tratamiento de Información, de
              obligatoria aplicación en todas las actividades que involucre,
              total o parcialmente, la recolección, el almacenamiento, el uso,
              la circulación y transferencia de esa información.
            </p>
            <p className="text-justify">
              Estas políticas son de obligatorio y estricto cumplimiento para
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, en calidad de responsable, así
              como todos los terceros que obran en nombre de la Compañía, o que
              sin actuar en nombre <b>OROZCO Y LAVERDE CIA S.A.S.</b>, tratan
              datos personales por disposición de ésta como encargados.
            </p>
            <p className="text-justify">
              Tanto el responsable como encargados, entiéndase, empleados,
              clientes, proveedores y terceros deben observar y respetar estas
              políticas en el cumplimiento de sus funciones y/o actividades aún
              después de terminados los vínculos legales, comerciales, laborales
              o de cualquier índole. De igual manera, se comprometen a guardar
              estricta confidencialidad en relación con los datos tratados.
            </p>
            <p className="text-justify">
              La presente se irá restructurando de acuerdo a la normatividad
              aplicable y nuevas disposiciones.
            </p>
            <p className="text-justify">
              Cualquier incumplimiento de las obligaciones en general, de las
              políticas contenidas en este documento debe ser reportado a
              comercial@orozcoylaverde.com
            </p>
          </section>
          <section className="mt-5">
            <h3 className="text-center mb-3">2</h3>
            <h4 className="text-center">Objetivo general</h4>
            <p className="text-justify">
              Con la implementación de esta política, se pretende garantizar la
              reserva de la información y la seguridad sobre el tratamiento que
              se les dará a la misma a todos los clientes, proveedores, y
              empleados de quienes <b>OROZCO Y LAVERDE CIA S.A.S.</b>, ha
              obtenido legalmente información y datos personales conforme a los
              lineamientos establecidos por la ley regulatoria del derecho al
              Habeas Data.
            </p>
          </section>
          <section className="mt-5">
            <h3 className="text-center mb-3">3</h3>
            <h4 className="text-center">
              Politicas manejo de informacion y privacidad
            </h4>
            <p className="text-justify">
              Los Datos Personales de los usuarios, clientes, proveedores, y
              empleados, son entregados por <b>OROZCO Y LAVERDE CIA S.A.S.</b>,
              Siempre que los usuarios, clientes, proveedores, y empleados den
              su aval, según se describe en esta Política de Privacidad y
              Protección de Datos Personales. Toda entrega a terceros de Datos
              Personales de usuarios, clientes, proveedores, y empleados, debe
              ser previamente autorizada por los mismos.
            </p>
            <p className="text-justify">
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, puede reunir, analizar y
              utilizar la información que obtiene de los usuarios, clientes,
              proveedores, y empleados, como un todo y así determinar su uso.
              Dicha información, que se utiliza principalmente para fines
              comerciales y laborales, puede ser revelada a las empresas cliente
              de OROZCO Y LAVERDE CIA S.A.S., de acuerdo a lo establecido en los
              presentes términos y condiciones.
            </p>
            <p className="text-justify">
              Los Datos Personales que los usuarios, clientes, proveedores, y
              empleados facilitan a <b>OROZCO Y LAVERDE CIA S.A.S.</b>, pueden
              ser ingresados por medio de canales electrónicos o físicos.
              Después de ingresar sus Datos Personales, los usuarios, clientes,
              proveedores, y empleados, pueden activar o desactivar su uso,
              decidiendo si desean dejar estos datos disponibles para procesos
              comerciales o laborales, es decir, ser incorporados en la base de
              datos que permanece a disposición de las empresas cliente.
            </p>
            <p className="text-justify">
              En el caso de que los Usuarios opten por no participar en procesos
              comerciales y laborales, quedarán almacenados bajo reserva en la
              base de datos de <b>OROZCO Y LAVERDE CIA S.A.S.</b> Cuando los
              usuarios, clientes, proveedores y empleados ingresan sus Datos
              Personales,
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, guarda esta información en sus
              archivos digitales y físicos, a los cuales revisa o actualiza de
              acuerdo a las necesidades del servicio de acuerdo a la información
              que entreguen. Por su parte, las empresas cliente al contratar los
              servicios ofrecidos por <b>OROZCO Y LAVERDE CIA S.A.S.</b>,
              declaran estar en conocimiento que la información que se le
              proporciona, y que la misma goza de la protección de privacidad
              establecida en el decreto 1377 de 2013, y la ley 1581 de 2012.
            </p>
            <p className="text-justify">
              <b>OROZCO Y LAVERDE CIA S.A.S.</b> provee a las empresas cliente
              la posibilidad de acceder a la información con el carácter de
              confidencial y/o reservado, obligándose para con la misma a
              guardar la más estricta y absoluta reserva. Los usuarios,
              clientes, proveedores y empleados pueden autorizar a{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, a publicar la información
              entregada según sea necesaria para la prestación de los servicios
              contratados. De igual manera, <b>OROZCO Y LAVERDE CIA S.A.S.</b>,
              podrá utilizar la información ingresada por los usuarios,
              clientes, proveedores y empleados, para el envío de información
              relativa a los servicios propios de la compañía, y eventualmente
              para la realización de consultas.{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, será responsable de la Base de
              Datos y de los Datos Personales recibidos.
            </p>
            <p className="text-justify">
              Con el objeto de evitar la pérdida, mal uso, alteración, acceso no
              autorizado y robo de los Datos Personales o Información
              Confidencial facilitados por los usuarios, proveedores, y personal
              en misión, <b>OROZCO Y LAVERDE CIA S.A.S</b>,. ha adoptado los
              niveles de seguridad y de protección de Datos Personales
              legalmente requeridos, y ha instalado todos los medios y medidas
              técnicas a su alcance. La cantidad de información que sea
              ingresada a <b>OROZCO Y LAVERDE CIA S.A.S.</b>, es voluntaria, sin
              que el ingresar distintos grados de información implique una
              diferencia en la calidad o cantidad de los servicios prestados por
              ella, a menos que se indique expresamente lo contrario. Los datos
              que se hayan obtenido con ocasión de relaciones laborales y/o
              comerciales sostenidas entre las partes, gozan del tratamiento
              indicado en los artículos 5, 6 y 10 de la ley 1581 de 2012, y 5,
              6, y 10 del decreto 1377 de 2013, por lo que no será necesaria la
              autorización de los usuarios, clientes, proveedores, y empleados
              para su uso, teniendo en cuenta que{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, tiene el deber legal de dar
              las certificaciones laborales y/o comerciales correspondientes.
            </p>
            <p className="text-justify">
              Los Usuarios y Clientes podrán ejercitar los derechos que esta
              Política de Privacidad y Protección de Datos Personales señala,
              solo si <b>OROZCO Y LAVERDE CIA S.A.S</b>, decidiera cambiar su
              Política de Privacidad y Protección de Datos Personales, lo cual
              se lo hará saber debidamente a través de medios magnéticos o
              físicos, a todos sus usuarios, proveedores y personal. Todo
              titular de datos personales tiene derecho a conocer, actualizar,
              rectificar, consultar sus datos personales en cualquier momento
              respecto a los datos que considere parciales, inexactos,
              incompletos, fraccionados y aquellos que induzcan a error,
              solicitar en cualquier momento una prueba de la autorización
              otorgada a <b>OROZCO Y LAVERDE CIA S.A.S.</b>, ser informado
              previa solicitud del titular de los datos, respecto del uso que le
              ha dado a los mismos, presentar ante la Superintendencia de
              Industria y Comercio las quejas que considere pertinentes para
              hacer valer su derecho al Habeas Data frente a la compañía.{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>
              realizara la supresión de datos personales de la base de datos, de
              los usuarios, proveedores, clientes o empleados, previa solicitud
              por escrito del titular de la misma.{" "}
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, no requiere autorización para
              el tratamiento de los datos que tiene en su poder cuando la
              información sea solicitada a la compañía por una entidad pública o
              administrativa que esté actuando en ejercicio de sus funciones
              legales o por orden judicial, Cuando se trate de datos de
              naturaleza pública debido a que éstos no son protegidos por el
              ámbito de aplicación de la norma, eventos de urgencia médica o
              sanitaria debidamente comprobadas, en aquellos eventos donde la
              información sea autorizada por la ley para cumplir con fines
              históricos, estadísticos y científicos y cuando se trate de datos
              relacionados con el registro civil de las personas debido a que
              ésta información no es considerada como un dato de naturaleza
              privada.
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, podrá entregar información sin
              necesidad de contar con la autorización de los titulares de los
              datos, a sus herederos o representantes en cualquier momento y a
              través de cualquier medio cuando así lo soliciten, a las entidades
              judiciales o administrativas en ejercicio de funciones que eleven
              algún requerimiento a la compañía para que le sea entregada la
              información, a los terceros que sean autorizados por alguna ley de
              la república de Colombia y a los terceros a los que el Titular del
              dato autorice expresamente entregar la información y cuya
              autorización sea entregada a <b>OROZCO Y LAVERDE CIA S.A.S.</b>{" "}
              Toda solicitud, petición, queja o reclamo que sea presentada a
              OROZCO Y LAVERDE CIA S.A.S. derivada de las{" "}
              <b>POLITICAS GENERALES DE MANEJO DE LA INFORMACION</b>, por
              cualquier titular o sus causahabientes respecto al manejo y
              tratamiento dado a su información será resuelta de conformidad con
              la ley regulatoria al derecho al habeas data y será tramitado bajo
              las siguientes reglas:
              <br />
              <ol type="1">
                <li>
                  <p className="text-justify">
                    La petición o reclamo se formulará mediante escrito o
                    cualquier otro de los medios definidos en la presente
                    política para tal fin, dirigido a{" "}
                    <b>OROZCO Y LAVERDE CIA S.A.S.</b>, con la identificación
                    del titular, la descripción de los hechos que dan lugar al
                    reclamo, la dirección o medio a través del cual desea
                    obtener su respuesta, y si fuere el caso, acompañando los
                    documentos soporte que considere. En caso de que el escrito
                    resulte incompleto, la compañía solicitará al interesado
                    para que subsane las fallas dentro de los cinco (5) días
                    siguientes a la recepción del reclamo. Transcurridos dos
                    meses desde la fecha del requerimiento, sin que el
                    solicitante presente la información requerida, se entenderá
                    que ha desistido de la reclamación o petición.
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    Una vez recibida la petición o reclamo completo, la compañía
                    incluirá en el registro individual en un término no mayor a
                    dos (2) días hábiles una leyenda que diga "reclamo en
                    trámite" y la naturaleza del mismo. Dicha información deberá
                    mantenerse hasta que el reclamo sea decidido.
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    El solicitante recibirá una respuesta por parte de{" "}
                    <b>OROZCO Y LAVERDE CIA S.A.S</b>, dentro de los diez (10)
                    días hábiles siguientes contados a partir de la fecha en la
                    cual ha tenido conocimiento efectivo de la solicitud.
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    Cuando no fuere posible atender la petición dentro de dicho
                    término, se informará al interesado, expresando los motivos
                    de la demora y señalando la fecha en que se atenderá su
                    petición, la cual en ningún caso podrá superar los cinco (5)
                    días hábiles siguientes al vencimiento del primer término.
                  </p>
                </li>
              </ol>
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-center mb-3">4</h2>
            <h4 className="text-center">CONSULTAS</h4>
            <p className="text-justify">
              La Política de manejo de la información personal por parte de
              <b>OROZCO Y LAVERDE CIA S.A.S.</b> y los derechos básicos que los
              titulares de los datos tienen en relación con la misma podrán ser
              consultados a través de los siguientes medios:
              www.orozcoylaverde.com,{" "}
              <b>POLITICAS GENERALES Y DE TRATAMIENTO DE LA INFORMACION.</b>
            </p>
            <p className="text-justify">
              Cuando un titular de la información considere necesario instaurar
              una solicitud de información o considere que sus derechos han sido
              vulnerados en relación con el uso y el manejo de su información,
              podrá hacerlo a través del siguiente correo electrónico:
              comercial@orozcoylaverde.com o en nuestras oficinas ubicadas en la
              Carrera 49 número 98-32 de la ciudad de Bogotá. Si dentro de los
              diez (10) días señalados, no fuere posible para la compañía
              atender la consulta, el área correspondiente deberá informar al
              interesado, los motivos de la demora e indicarle la fecha en que
              se atenderá la misma, la cual en ningún caso podrá superar los
              cinco (5) días hábiles siguientes al vencimiento del primer
              término.
            </p>
          </section>
          <section className="mt-5">
            <h3 className="text-center mb-3">5</h3>
            <h4 className="text-center">RESPONSABLE DEL TRATAMIENTO</h4>
            <p className="text-justify">
              <b>OROZCO Y LAVERDE CIA S.A.S.</b> Tiene la calidad de responsable
              del tratamiento, a través de la presente política se permite
              informar sus datos:
              <br />
              <br />
              <p className="text-justify">
                <b>
                  Razón social: OROZCO Y LAVERDE CIA S.A.S.<br></br>NIT:
                  860.046.812-2<br></br>
                  Domicilio principal: CARRERA 49 NUMERO 98-32 DE BOGOTA
                  <br></br>
                  Teléfono: (601) 6210200
                </b>
              </p>
            </p>
            <p className="text-justify">
              Persona o dependencia responsable de la atención de peticiones,
              consultas y reclamos: el área encargada de recibir y canalizar
              todas las solicitudes e inquietudes a través del correo
              electrónico comercial@orozcoylaverde.com
            </p>
            <p className="text-justify">
              <b>OROZCO Y LAVERDE CIA S.A.S.</b>, garantiza que da cumplimiento
              a la protección de los datos personales suministrados por sus
              clientes en virtud de lo dispuesto en la normatividad regulatoria
              del derecho al HABEAS DATA, para lo cual se permite informar:
              <br />
              <ol type="1">
                <li>
                  <p className="text-justify">
                    Que el derecho de hábeas data es aquel que tiene toda
                    persona de conocer, actualizar y rectificar de forma
                    gratuita la información que se haya recogido sobre ella en
                    archivos y bancos de datos de naturaleza pública o privada.
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    Que el cliente como titular de la información podrá acceder
                    a sus datos en cualquier momento, por lo cual podrá
                    modificarlos, corregirlos, actualizarlos conforme a las
                    <b>
                      POLITICAS GENERALES Y DE TRATAMIENTO DE LA INFORMACION
                    </b>
                    , y revocar y solicitar prueba de la autorización dada si
                    así lo considera a través del correo electrónico o a través
                    de las oficinas de <b>OROZCO Y LAVERDE CIA S.A.S.</b>
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    Que el cliente como titular de la información tiene la
                    facultad o no de informar aquellos datos que libremente
                    disponga y de elevar solicitudes respecto al uso que se la
                    haya dado a sus datos.
                  </p>
                </li>
                <li>
                  Que, para el ejercicio pleno y efectivo de este derecho por
                  parte de todos sus clientes,{" "}
                  <b>OROZCO Y LAVERDE CIA S.A.S.</b>, ha dispuesto los
                  siguientes medios a través de los cuales podrán presentar sus
                  solicitudes y/o quejas y/o reclamos:
                  comercial@orozcoylaverde.com
                </li>
              </ol>
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default DataTreatment;
